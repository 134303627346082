import {
  OrderItem,
  PagedOrderRequest,
  PagedSortRequest,
} from "@kalmarenergi/util/models/request";

/**
 * Temporarily handles both sortBy and orderBy request objects
 *
 * TODO: Will need refactor once all APIs support the orderBy parameter
 */
export function serializeOrderDirection<
  T extends PagedSortRequest & PagedOrderRequest,
>(request: T): T {
  const { sortBy, orderBy, sortDescending } = request;

  if (!sortBy && !orderBy) {
    return request;
  }

  const requestWithDirection: T = {
    ...request,
    orderBy: orderBy || sortBy,
  };

  if (Array.isArray(requestWithDirection.orderBy)) {
    requestWithDirection.orderBy = requestWithDirection.orderBy.map((item) => {
      if (typeof item === "string") {
        return `${sortDescending ? "-" : ""}${item}`;
      }

      return `${item.direction === "desc" ? "-" : ""}${applyNullsOrder(item)}`;
    });

    return requestWithDirection;
  }

  if (typeof sortDescending === "undefined") {
    return requestWithDirection;
  }

  const prefixedOrderBy = `${sortDescending ? "-" : ""}${requestWithDirection.orderBy}`;

  delete requestWithDirection.sortDescending;
  delete requestWithDirection.sortBy;

  return {
    ...requestWithDirection,
    sortBy: sortBy ? prefixedOrderBy : undefined,
    orderBy: prefixedOrderBy,
  };
}

/**
 * Applies nulls order to the given order item.
 * If the nulls order is not specified, returns the field as is.
 * If the nulls order is specified, returns the field with the appropriate nulls order function.
 *
 * More information on how the API handles nulls order can be found in the [API documentation](https://docs.inma.kalmarenergi.se/guidlines/api#orderging-in-get-api).
 *
 * @param orderItem - The order item to apply nulls order to.
 * @returns The field with the applied nulls order.
 */
function applyNullsOrder(orderItem: OrderItem) {
  if (!orderItem.nullsOrder) {
    return orderItem.field;
  }

  if (orderItem.nullsOrder === "lastAsc") {
    return `nulls${orderItem.direction === "asc" ? "last" : "first"}(${orderItem.field})`;
  }

  if (orderItem.nullsOrder === "lastDesc") {
    return `nulls${orderItem.direction === "desc" ? "last" : "first"}(${orderItem.field})`;
  }

  return `nulls${orderItem.nullsOrder === "first" ? "first" : "last"}(${orderItem.field})`;
}
