import { HttpClient, HttpParams } from "@angular/common/http";
import { BaseModel } from "@kalmarenergi/util/models/base";
import { PagedResponse } from "@kalmarenergi/util/models/response";
import { serializeParameters } from "@kalmarenergi/util/serializers";
import { Observable, map, take } from "rxjs";
import { KeRequests, SearchRequest } from "../models/request";

export class ReadRepository<T extends BaseModel> {
  constructor(
    protected readonly http: HttpClient,
    protected path: string,
  ) {}

  public get<GetRequest extends SearchRequest>(
    request: GetRequest,
  ): Observable<PagedResponse<T>> {
    return this.http.get<PagedResponse<T>>(`${this.path}`, {
      params: new HttpParams({
        fromObject: serializeParameters(request),
      }),
    });
  }

  public getItems<GetRequest extends SearchRequest>(
    request: GetRequest,
  ): Observable<T[]> {
    return this.get(request).pipe(map((x) => x.items));
  }

  public count<GetRequest extends SearchRequest>(
    request: GetRequest = KeRequests.count(),
  ): Observable<number> {
    return this.get(request).pipe(
      map((x) => x.count),
      take(1),
    );
  }

  public getById(resourceId: string): Observable<T> {
    return this.http.get<T>(`${this.path}/${resourceId}`);
  }
}
