import {
  RequestFilter,
  SearchRequest,
} from "@kalmarenergi/util/models/request";
import { serializeOrderDirection } from "./order-direction.serializer";

/**
 * Generates a filter string for a given field, operator, and value.
 * @param field The field to filter on.
 * @param operator The operator to use for the filter.
 * @param value The value to filter on.
 * @returns The generated filter string.
 */
export const getFilterString = (requestFilter: RequestFilter): string => {
  const { field, operator, value } = requestFilter;

  if (Array.isArray(value)) {
    return `${field}:${operator}:${JSON.stringify(value)}`;
  }

  if (value instanceof Date) {
    return `${field}:${operator}:${value.toISOString()}`;
  }

  return `${field}:${operator}:${value}`;
};

/**
 * Serializes an array of request filters into an array of serialized filter strings.
 * @param requestFilters The array of request filters to serialize.
 * @returns An array of serialized filter strings.
 */
export function serializeFilters(requestFilters: RequestFilter[]): string[] {
  const serializedFilters: string[] = [];

  for (let requestFilter of requestFilters) {
    const { value } = requestFilter;

    if (
      value === undefined ||
      value === null ||
      value === "" ||
      (Array.isArray(value) && !value.length)
    ) {
      continue;
    }

    serializedFilters.push(getFilterString(requestFilter));
  }

  return serializedFilters;
}

interface RequestParams {
  [key: string]: any;
}

/**
 * Serializes request parameters, converting Date objects to ISO string format
 * and serializing filters into filter strings.
 * @param data The request parameters to serialize.
 * @returns The serialized request parameters.
 */
export function serializeParameters(data: RequestParams & SearchRequest): any {
  data = serializeOrderDirection(data);

  for (const key in data) {
    if (
      data[key] === undefined ||
      data[key] === null ||
      data[key] === "" ||
      (Array.isArray(data[key]) && !data[key].length)
    ) {
      delete data[key];
      continue;
    }

    if (data[key] instanceof Date) {
      data[key] = data[key].toISOString();
      continue;
    }

    if (key.toLowerCase() === "filters") {
      data[key] = serializeFilters(data[key]);
    }
  }
  return data;
}
