import {
  Observable,
  Subject,
  exhaustMap,
  filter,
  map,
  scan,
  startWith,
  switchMap,
  takeWhile,
  tap,
} from "rxjs";
import { BaseModel } from "../models/base";
import { PagedResponse } from "../models/response";
import { NestedReadRepository, ReadRepository } from "../repositories";

export interface AutocompleteConfig<T extends BaseModel> {
  repository: ReadRepository<T>;
  nextPage: Subject<any>;
  orderBy?: string;
}

export interface AutocompleteNestedConfig<T extends BaseModel> {
  repository: NestedReadRepository<T, any>;
  resourceId: string;
  nextPage: Subject<any>;
  orderBy?: string;
}

export const autocomplete =
  <T extends BaseModel>(
    config: AutocompleteConfig<T> | AutocompleteNestedConfig<T>,
  ) =>
  (source: Observable<any>): Observable<T[]> =>
    source.pipe(
      filter((value) => typeof value === "string"),
      switchMap((value) => {
        let currentPage = 1;
        return config.nextPage.pipe(
          startWith(currentPage),
          exhaustMap((_) => {
            const input = {
              query: value as string,
              pageIndex: currentPage,
              pageSize: 10,
              orderBy: config.orderBy || "Name",
              sortDescending: false,
            };
            if (config.repository instanceof ReadRepository) {
              return config.repository.get(input);
            } else {
              return config.repository.get(
                (config as AutocompleteNestedConfig<T>).resourceId || "",
                {
                  pageIndex: currentPage,
                  pageSize: 10,
                  orderBy: config.orderBy,
                },
              );
            }
          }),
          tap(() => currentPage++),
          takeWhile((r) => (r as PagedResponse<T>).hasNext, true),
          map((r) => (r as PagedResponse<T>).items),
          scan((all: T[], newItems: T[]) => all.concat(newItems), []),
        );
      }),
    );
