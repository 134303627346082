import { HttpClient } from "@angular/common/http";
import { BaseModel } from "@kalmarenergi/util/models/base";
import { Observable } from "rxjs";
import { PagedRequest } from "../models/request";
import { NestedReadRepository } from "./nested-read.repository";

export class NestedRepository<
  T extends BaseModel,
  GetRequest extends PagedRequest,
  CreateRequest extends Partial<T>,
  SaveRequest extends Partial<T>,
> extends NestedReadRepository<T, GetRequest> {
  constructor(
    protected override readonly http: HttpClient,
    protected override basePath: string,
    protected override subPath: string,
  ) {
    super(http, basePath, subPath);
  }

  public create(resourceId: string, request: CreateRequest): Observable<T> {
    return this.http.post<T>(`${this.getPath(resourceId)}`, request);
  }

  public batchCreate(
    resourceId: string,
    request: CreateRequest[],
  ): Observable<T[]> {
    return this.http.post<T[]>(`${this.getPath(resourceId)}/batch`, request);
  }

  public update(
    resourceId: string,
    subResourceId: string,
    request: SaveRequest,
  ): Observable<T> {
    return this.http.put<T>(
      `${this.getPath(resourceId)}/${subResourceId}`,
      request,
    );
  }

  public delete(resourceId: string, subResourceId: string): Observable<void> {
    return this.http.delete<void>(
      `${this.getPath(resourceId)}/${subResourceId}`,
    );
  }

  public batchDelete(
    resourceId: string,
    subResourceIds: string[],
  ): Observable<void> {
    return this.http.delete<void>(`${this.getPath(resourceId)}/batch`, {
      body: subResourceIds,
    });
  }
}
